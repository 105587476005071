<template>
<div>
    <el-row class="margin_b">
        <el-col :span="5">
            <el-select v-model="list.parkId" placeholder="请选择园区" class="centerSelInput ipwidth">
                <el-option v-for="item in parkSelect"  :key="item.parkId"  :label="item.parkName"  :value="item.parkId"></el-option>
            </el-select>
        </el-col>
        <el-col :span="7"  >
              <el-date-picker v-model="list.upTime" 
                type="daterange" 
                range-separator="-"  
                start-placeholder="开始日期"  
                end-placeholder="结束日期" 
                class="pickerTime"
                value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-col>
        <el-col :span="8">
            <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="searchData_B">搜索</el-button>
            <el-button type="primary" size="medium" class="centerBtn resetbtn" @click="clearData">重置</el-button>
            <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="uplog_btn">上传日志</el-button>
        </el-col>
    </el-row>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="list.total"
      :page="list.page"
      :size="list.size"
      :loading="list.loading"
      @getAjax="getTableData"
    />

    <el-dialog  title="删除" :visible.sync="dialogVisible" width="30%"  >
      <div>您当前正在执行删除<span>【{{dialog_logName}}】</span>的操作，确认继续吗？</div> 
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteUplog">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>


    <el-drawer title="上传日志" :visible.sync="importlogConfig.visible" :direction="direction" :destroy-on-close="true"
			@close="handleCloseImportlogDrawer" >
      <el-upload	drag	class="upload-demo"	action="#"	:auto-upload="false"	:file-list="importlogConfig.logFileList"
				:on-change="handleSelectUploadFile"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span class="red">注：如果上传日志，请参照模版，批量导入日志。</span>
          <span class="textCol" style="cursor: pointer;" @click="muban_down">下载模板</span>
        </div>
      </el-upload>
      <el-form :model="upRuleForm"  ref="upRuleForm" label-width="100px"  class="upload_from">
        <el-form-item label="选择园区：" prop="parkId"   :rules="{ required: true, message: '请选择园区'}">
           <el-select v-model="upRuleForm.parkId" placeholder="请选择园区" class="centerSelInput ipwidth">
                <el-option v-for="item in parkSelect"  :key="item.parkId"  :label="item.parkName"  :value="item.parkId"></el-option>
            </el-select>
        </el-form-item>
      </el-form> 
      <div style="margin-top: 20%;padding:0 0 0 10%" >
        <button class="centerCzbtn" @click="handleImportlog">上传</button>
      </div>
    </el-drawer>

 </div>   
</template>

<script>
 


import {
      _findAllPage,
        _deleteData,
        _Download,
        _Execlupload,
        _mubanDown,
        _ParkList} from "../../api/operationControlLog"
import  Table  from '../../components/Table.vue' 
export default {
  components: {
   Table
  },
  data() {
    return {
        drawer: false,
        direction: 'rtl',
        dialogVisible:false,
        //弹窗日志名 加上当前的id
        dialog_logName:null,
        dialog_id:null,
        //下载内容弹窗
        dialogDownVisible:false,
        //上传园区选择数据
        dialogSelectpark:null,
        upRuleForm:{
          parkId:null
        },
        upRules:{
           name: [
            { required: true, message: '请选择园区', trigger: 'blur' },
          ],
        },
        handleClose:false,
        importlogConfig: {
          visible: false,
          isImport: false,
          logFileList: [],
        },
         //表格数据
         tableData:[
         ],
        //园区下拉选择器
        parkSelect:[
        ],
        list: {
            page: 1,
            size: 10,
            total: 0,
            upTime:[],
            parkId:null,
            loading: false,
        },
        columns:[
        {
          label: "园区",
          prop: "parkName",
        },
        {
          label: "日志名称",
          prop: "logName",
        },
        {
          label: "上传日期",
          prop: "uploadTime",
        },

        {
          label: "上传对象",
          prop: "createPerson",
        },
        {
          label: "操作项",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA colorB",
                  on: {
                    click: () => {
                      this.dialog_logName =  params.row.logName
                      this.dialog_id = params.row.id
                      this.log_down()
                    },
                  },
                },
                "下载日志"
              ),
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.dialogVisible = true
                      this.dialog_logName =  params.row.logName
                      this.dialog_id = params.row.id
                      
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
        ],
        
    };
  },
  created(){
        
  },
  mounted() {
    this.getNowFormatDate() 
    //获取园区下拉框
    this.getPark()
    this.searchData()

  },

  methods: {
      //m默认时间
      getNowFormatDate() {
           //获取当前日期
            var myDate = new Date();
            var nowY = myDate.getFullYear();
            var nowM = myDate.getMonth()+1;
            var nowD = myDate.getDate();
            //获取三十天前日期
            var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
            var lastY = lw.getFullYear();
            var lastM = lw.getMonth()+1;
            var lastD = lw.getDate();
            this.list.upTime=[]
            this.list.upTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD));
            this.list.upTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD));             
    },


    async getPark() {
      let arr=[]
      let { success, data, message } = await _ParkList();
        for (let company of Object.values(data)) {
           for(let i =0;i<company.length;i++){
             arr.push(company[i])
           }
        }
        for(let i =0;i<arr.length;i++){
            let  senobj={
              parkId:null,
              parkName:null
            }
              senobj.parkId = arr[i].id 
              senobj.parkName = arr[i].companyName
            this.parkSelect.push(senobj)
           }
    },

  //获取数据
     async searchData(){
        this.list.loading =true
        let reqData={
          page : this.list.page,
          size : this.list.size,
          parkId : this.list.parkId,
          startTime :this.list.upTime[0],
          endTime : this.list.upTime[1]
        }
        await _findAllPage(reqData).then((res)=>{
          if(res.success){
              this.list.page = res.data.pageNum
              this.list.size = res.data.pageSize
              this.list.total = res.data.total
              this.tableData = res.data.list
          }else{
            this.$message.error(res.message)
          }  
            this.list.loading = false;
        })
    },
    searchData_B(){
      this.list.page = 1;
      this.searchData()
    },

    clearData(){
      //时间默认
      this.getNowFormatDate()
      //搜索项默认
      this.list.parkId=null
      this.list.size =10
      this.list.page = 1
      this.searchData()
    },
  //上传大模块
    handleOpenImportlogDrawer(){
			this.importlogConfig.visible = true;
		},
    handleCloseImportlogDrawer(){
			this.importlogConfig.visible = false;
			this.importlogConfig.logFileList = [];
			this.upRuleForm.parkId =null;
			this.importlogConfig.isImport = false;
		},
    handleSelectUploadFile(file, fileList){
			this.importlogConfig.logFileList = fileList;
		},
    async handleImportlog(){
     
      if(this.importlogConfig.logFileList[0]){
           if(this.upRuleForm.parkId == null){
            this.$message({
              showClose: true,
              message: '请选择园区',
              type: 'error'
            });
            return
          }

          const loading = this.$loading({
            lock: true,
            text: '导入中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          let file = new FormData();
          file.append("file", this.importlogConfig.logFileList[0].raw);
          file.append("parkId", this.upRuleForm.parkId);
          this.importlogConfig.isImport = true;
          console.log(file)
          await _Execlupload(file).then((res) => {
            if(res != undefined){
              if (res.success == true) {
                this.handleCloseImportlogDrawer();
                this.searchData()
                this.$message.success(res.message);
                loading.close();
              }   
            }else{
              loading.close();
            } 
          });
        
      }else{
        this.$message({
          showClose: true,
          message: '没有上传文件',
          type: 'error'
        });
      }
		},
    uplog_btn(){
      	this.importlogConfig.visible = true;
    },
    //下载模板
    async muban_down(){
      await _mubanDown().then((response)=>{
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          this.exportLoad = false;

          link.download = `运营管控日志模板.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
        });
    },
    //下载日志
  async log_down(){
      console.log(this.dialog_id)
     
    await  _Download({id:this.dialog_id}).then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          this.exportLoad = false;

          link.download = `${this.dialog_logName}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
        });
    },



    //删除本条数据
   async deleteUplog(){
      await _deleteData({id : this.dialog_id}).then((res)=>{
          console.log(res)
          if(res.success){
            this.$message.success("删除成功")
            this.dialogVisible = false
          }else if(res.success == false){
            this.$message.success("删除失败")
            this.dialogVisible = false
          }
      })
      this.searchData()   
    },
 



     
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      await this.searchData();
    },
  }
};
</script>
<style scoped>
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 0px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.second_row{
    margin:1% 0 1% 0;
}
.pickerTime{border:0px solid black; }
.margin_b{
  margin-bottom: 2%;
}
.centerSelInput{
  height:30px;
}
.textCol {
  color: #1683d9;
}
/deep/ .colorB {
  /* color: red; */
  /* -webkit-touch-callout: none; */
  margin-right: 20px;
}
.upload_from{
  margin:10% 0 0 -5%
}
/deep/ .flex{
  border-bottom: 2px dashed rgb(255, 255, 255);
}
</style>
