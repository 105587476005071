import service from '@/until/api'

export function _findAllPage(data) {
	return service({
		url: '/system-nacos/operationManagementLog/findAllPage',
		method: 'GET',
        params: data
	})
}
//删除
export function _deleteData(data) {
	return service({
		url: '/system-nacos/operationManagementLog/delete',
		method: 'GET',
        params: data
	})
}

//下载
export function _Download(data) {
	return service({
		url: '/system-nacos/operationManagementLog/download',
		method: 'GET',
		responseType: 'blob',
        params: data
	})
}


//上传
export function _Execlupload(data) {
    return service({
      url: '/system-nacos/operationManagementLog/uploadOperationManageLog',
      method: 'POST',
      data
    })
}

//下载模板

export function _mubanDown(data) {
	return service({
		url: '/file-nacos/excelDownload/exportOperationManageLogTemplate',
		method: 'GET',
		responseType: 'blob',
        params: data
	})
}

//园区选择
export function _ParkList(data) {
    return service({
        url: '/user-nacos/company/findAllPark',
        method: 'GET',
        params: data
    })
}


